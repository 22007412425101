<template>
  <div class="progress_upload" v-if="loading">
    <div class="progress-bar bg-success">
      <span>Progress Upload Video . . . .</span>
      <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
      </div> 
      
    </div>
  </div>
  <div class="progress_upload" v-if="problemJaringan">
    <div class="progress-bar bg-danger">
      <span>
        Anda Sedang Tidak Terhubung , Silahkan
        <a href="javascript:void(0)" @click="reloadPage">Refresh Halaman Ini</a>
      </span>
      
    </div>
  </div>
  <RouterView :key="$route.fullPath" />
</template>

<style lang="scss">
.progress_upload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #f5f5f5;
  z-index: 999999;
  .progress-bar {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
</style>

<script setup>
import { RouterView } from "vue-router";
import { onMounted, ref } from "vue";
import "@/assets/scss/app.scss";

const loading = ref(false);
const problemJaringan = ref(false);

const reloadPage = () => {
  window.location.reload();
};

onMounted(() => {
  localStorage.setItem("url", window.location.origin);
  // interval get localstorage loading_upload video
  setInterval(() => {
    getLocalStorageUpload();
    getProblemJaringan();
  }, 1000);
});

const getLocalStorageUpload = () => {
  const loading_upload = localStorage.getItem("loading_upload");
  if (loading_upload) {
    if (loading_upload === "true") {
      loading.value = true;
    } else {
      loading.value = false;
    }
  }
};
const getProblemJaringan = () => {
  const problem_jaringan = localStorage.getItem("problem_jaringan");
  if (problem_jaringan) {
    if (problem_jaringan === "true") {
      problemJaringan.value = true;
    } else {
      problemJaringan.value = false;
    }
  }
};
</script>
