import axios from "axios";
import { specialError } from "./validasi";

const Bridge = () => {
  let headers = {
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Content-Type":
      "application/json;multipart/form-data;application/x-www-form-urlencoded",
  };

  return headers;
};

const InLine = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: Bridge(),
});
export const getAborted = async (url) => {
  const source = axios.CancelToken.source()

  const request = await InLine.get(url, { cancelToken: source.token })

  return {
    data: request.data,
    cancel: source.cancel
  }
}

export const GetLine = async (url) => {
  try {
    const response = await getAborted(url)
    return response.data
  } catch (error) {
    if (error?.response?.data) {
      const { message } = error.response.data

      if (specialError[message]) {
        if (message === 'relogin') {
          localStorage.clear()
          window.location = '/login'
        } else {
          window.location = '/'
        }
      } else {
        throw error.response.data
      }
    }
  }
}

export const DeleteLine = async (url) => {
  try {
    const response = await InLine.delete(url);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.data) {
        const errorData = error.response.data;
        const checkingError = specialError;
        if (checkingError[errorData.message]) {
          if (checkingError[errorData.message] === "relogin")
            localStorage.clear();
          window.location =
            checkingError[errorData.message] === "relogin" ? "/login" : "/";
        } else {
          throw error.response.data;
        }
      }
    }
  }
};
const apakahArray = (myArray) => {
  myArray = myArray === null ? "" : myArray
  return myArray.constructor === Array;
};
export const PostLine = async (url, data, edit = false) => {
  try {
    let formData = new FormData();
    const reGenerate = Object.keys(data);
    reGenerate.forEach((element) => {
      if (apakahArray(data[element])) {
        data[element].forEach((itemElement) => {
          formData.append(`${element}[]`, itemElement);
        });
      } else {
        formData.append(element, data[element] === null ? "" : data[element]);
      }
    });
    if (edit) {
      formData.append("_method", "patch");
    }
    const dataPayload = formData;
    let accessData = null;
    accessData = await InLine.post(url, dataPayload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return accessData;
  } catch (error) {
    console.log(error)
    if (error.response) {
      if (error.response.data) {
        const errorData = error.response.data;
        const checkingError = specialError;
        if (checkingError[errorData.message]) {
          if (checkingError[errorData.message] === "relogin")
            localStorage.clear();
          window.location =
            checkingError[errorData.message] === "relogin" ? "/login" : "/home";
        } else {
          const errorData = {
            error: error.response.data,
          };
          throw errorData;
        }
      }
    } else {
      return error.response;
    }
  }
};
