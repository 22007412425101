
<template>
  <div class="frama__app">
    <div class="header__page">
      <router-link to="/" class="left__content">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </div>
        <div class="title__web">
          <h1>VIRAL BALIKPAPAN</h1>
          <span>Kantor Pertanahan Kota Balikpapan</span>
        </div>
      </router-link>
      <div class="right__content">
        <router-link to="/register" class="btn__regis">
          <img src="@/assets/images/link.svg" alt="">
          <span>
            Registrasi Layanan
          </span>
        </router-link>
        <router-link to="/room" class="btn__pink">
          <img src="@/assets/images/area.svg" alt="">
          <span>
            Masuk Room
          </span>
        </router-link>
      </div>
    </div>
    <div class="body__page">
      <div class="left__body">
        <div class="form__frame">
          <template v-if="loading">
            <div class="text-center w-100">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="form__register">
              <div class="header__pelayanan">
                <div class="site__area">
                  <img src="@/assets/images/area.svg" alt="">
                </div>
                <h4>Registrasi Pelayanan</h4>
              </div>
              <form @submit.prevent="recaptcha" class="row g-3">
                <!-- <div class="col-md-12">
                  <label for="layanan_id" class="form-label">Pilih Pelayanan</label>
                  <select required class="form-select" id="layanan_id" v-model="form.layanan_id" placeholder="Layanan">
                    <option value="" selected disabled>--- {{ pelayanans.length === 0 ? `Pelayanan Tidak Tersedia` : 'Pilih Pelayanan' }} ---</option>
                    <option v-for="pelayanan in pelayanans" :key="pelayanan.id" :value="pelayanan.id">
                      {{ pelayanan.nama }} (Kuota: {{ pelayanan.kuota_tersedia }})
                    </option>
                  </select>
                </div> -->
                <div class="col-md-12">
                  <label for="nik" class="form-label">NIK</label>
                  <input required type="text" class="form-control" id="nik" v-model="form.nik" placeholder="NIK">
                </div>
                <div class="col-md-12">
                  <label for="nama" class="form-label">Nama</label>
                  <input required type="text" class="form-control" id="nama" v-model="form.nama" placeholder="Nama">
                </div>
                <div class="col-md-12">
                  <label for="alamat" class="form-label">Alamat</label>
                  <textarea 
                    required 
                    class="form-control" 
                    placeholder="Alamat anda ..." 
                    name="alamat" 
                    id="alamat" 
                    v-model="form.alamat" 
                    cols="30" 
                    rows="10"
                  >
                  </textarea>
                </div>
                <div class="col-md-12">
                  <label for="email" class="form-label">Email</label>
                  <input required type="email" class="form-control" id="email" v-model="form.email" placeholder="Email">
                </div>
                <div class="col-md-12">
                  <label for="no_hp" class="form-label">No. HP</label>
                  <input required type="text" class="form-control" id="no_hp" v-model="form.no_hp" placeholder="No. HP">
                </div>
                <div class="col-md-12">
                  <label for="keperluan" class="form-label">Keperluan</label>
                  <textarea required class="form-control" placeholder="Jelaskan keperluan anda ..." name="keperluan" id="keperluan" v-model="form.keperluan" cols="30" rows="10"></textarea>
                </div>
                <div class="col-md-12">
                  <label for="file" class="d-block cap"
                    >
                    Berkas Pendukung (Optional) <br>
                    <span style="font-size: 12px; color:red; font-style: italic;">
                      (Digunakan melampirkan berkas pendukung seperti surat tanah, kuwitansi dan sebagainya. Berkas yang diupload harus berformat gambar atau pdf. Maksimal ukuran file 2MB dan Dijadikan 1 File.)
                    </span>
                    </label
                  >
                  <input
                    id="file"
                    type="file"
                    accept="image/*,.pdf,.doc,.docx"
                    @change="(e) => onChangeFile(e, 'file')"
                    name="file"
                    class="form-control"
                    placeholder="Upload Berkas"
                  />
                </div>
                <!-- make check confirm  -->
                <!-- <div class="col-md-12">
                  <label for="recaptcha" class="form-label">
                    <input type="checkbox" id="recaptcha" v-model="checkconfirm" />
                    Saya menyatakan informasi yang saya berikan adalah <b>benar</b> dan <b>sesuai</b>.
                  </label>
                </div> -->
                <div class="col-md-12">
                  <label for="recaptcha" class="form-label">
                    <input type="checkbox" id="recaptcha" v-model="checkconfirm" />
                    Saya menyetujui <b>Kebijakan Privasi</b> dan <b>Persyaratan Layanan</b> yang berlaku pada penyedia layanan.
                  </label>
                </div>
                <div class="col-12">
                  <button type="submit" v-if="checkconfirm" class="btn btn-primary">Submit</button>
                  <button type="button" disabled v-else class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </template>
        </div>
      </div>
      <div class="right__body">
        <div class="list__pelayanan">
          <div class="header__pelayanan">
            <div class="mic__area">
              <img src="@/assets/images/mic.svg" alt="">
            </div>
            <h3>Pelayanan</h3>
          </div>
          <template v-if="loading">
            <div class="text-center w-100">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
          <template v-else v-for="sidePelayanan in pelayanans" :key="sidePelayanan.id">
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                <b>{{ sidePelayanan.nama }}</b>
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="kuota__pelayanan">
                Sisa Kuota : {{ `${sidePelayanan.kuota_tersedia}` }}
                <!-- {{ sidePelayanan.buka ? `${sidePelayanan.kuota} Kuota` : 'Pelayanan Tutup' }} -->
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                Jam Buka : {{ sidePelayanan.jam_buka }}
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                Jam Tutup : {{ sidePelayanan.jam_tutup }}
              </div>
            </div>
          </template>
          <div class="item__pelayanan" v-if="pelayanans.length === 0">
            <div class="title__pelayanan">
              Pelayanan sedang tutup
            </div>
          </div>
        </div>
        <div class="credits__body">
          <div class="header__pelayanan">
            <div class="site__area">
            <img src="@/assets/images/area.svg" alt="">
            </div>
            <h4>Informasi & Pertanyaan </h4>
          </div>
          <div class="detail__credits">
            <h5>Kantor Pertanahan <br> Kota Balikpapan</h5>
            <span>Jl. Manuntung No. 03, Balikpapan</span>
            <span>Telp. (0542) 8514647</span>
            <div class="copyright">
              <span>© {{ year }} Viral BPN</span>
              <span> Developed with love by <a href="https://dawnesiaofficial.com" target="_blank">Dawnesia</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { createToaster } from "@meforma/vue-toaster";
import { GetLine, PostLine } from '@/bridge/connection.js';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useRouter } from 'vue-router';

const router = useRouter();

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const loading = ref(true);

const toaster = createToaster({
  position: "top-right",
});

const year = ref(new Date().getFullYear());

const pelayanans = ref([]);

const checkconfirm = ref(false);

const form = ref({
  layanan_id: '',
  nik: '',
  nama: '',
  alamat: '',
  email: '',
  no_hp: '',
  keperluan: '',
  file: ''
});

onMounted(() => {
  getPelayanan();
});

const getPelayanan = async () => {
  try {
    const result = await GetLine('api/list-pelayanan');
    pelayanans.value = result.data;
    // get layanan with array 0 if result.data > 0
    if (result.data.length > 0) {
      form.value.layanan_id = result.data[0].id;
    }
    loading.value = false;
  } catch (error) {
    console.log(error);
  }
}

const recaptcha = async () => {
  if (checkconfirm.value) {
    loading.value = true;
    // (optional) Wait until recaptcha has been loaded.
    await recaptchaLoaded()
  
    // Execute reCAPTCHA with action "login".
    const token = await executeRecaptcha('login')
  
    // Do stuff with the received token.
    if (token) {
      submitForm();
    }
  }
}

const submitForm = async () => {
  try {
    loading.value = true;
    await PostLine('api/submit-registrasi', form.value);
    // reset form
    form.value = {
      layanan_id: '',
      nik: '',
      nama: '',
      alamat: '',
      email: '',
      no_hp: '',
      keperluan: '',
      file: ''
    };
    getPelayanan();
    toaster.success("Aksi Berhasil: Silahkan cek email anda untuk melihat informasi selanjutnya");
    router.push({ name: 'home' });
  } catch (error) {
    loading.value = false;
    if (error.error?.data?.data == 'DATA_NOT_FOUND') {
      toaster.error("Aksi Gagal: Layanan tidak ditemukan!");
    }
    else if (error.error?.data?.data == 'KUOTA_HABIS') {
      toaster.error("Aksi Gagal: Kuota pelayanan sudah habis!");
    }
    else if (error.error?.data?.data == 'HARI_TUTUP') {
      toaster.error("Aksi Gagal: Tidak ada Pelayanan Untuk hari ini!");
    }
    else if (error.error?.data?.data == 'HARI_NOT_FOUND') {
      toaster.error("Aksi Gagal: Tidak ada pelayanan!");
    }
    else if (error.error?.data?.data == 'NOT_IN_TIME') {
      toaster.error("Aksi Gagal: Layanan belum dibuka!");
    } else {
      toaster.error("Aksi Gagal: Mohon isi Semua Form!");
    }
  }
}

// @change file input
const onChangeFile = (e, formTarget) => {
  let file = e.target.files[0];
  if (file) {
    let formData = form.value;
    formData[formTarget] = file;
    form.value = formData;
  }
};
</script>