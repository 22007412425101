<template>
  <div class="frama__app">
    <div class="header__page">
      <router-link to="/" class="left__content">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </div>
        <div class="title__web">
          <h1>VIRAL BALIKPAPAN</h1>
          <span>Kantor Pertanahan Kota Balikpapan</span>
        </div>
      </router-link>
      <div class="right__content">
        <router-link to="/register" class="btn__regis">
          <img src="@/assets/images/link.svg" alt="">
          <span>
            Registrasi Layanan
          </span>
        </router-link>
        <router-link to="/room" class="btn__pink">
          <img src="@/assets/images/area.svg" alt="">
          <span>
            Masuk Room
          </span>
        </router-link>
      </div>
    </div>
    <div class="body__page">
      <div class="left__body">
        <div class="header__body">
          <div class="logo">
            <img src="@/assets/images/logo.png" alt="">
          </div>
          <div class="page__image"></div> 
        </div>
        <div class="content__body">
          <div class="text__content">
            <h4>Selamat Datang di <b>VIRAL BALIKPAPAN</b></h4>
            <p>
              VIRAL BALIKPAPAN adalah Sistem untuk melakukan layanan virtual menggunakan video call antara pemohon dengan petugas
              Kantor Pertanahan Kota Balikpapan, sehingga dengan layanan ini pemohon dapat menerima layanan dimanapun pemohon berada.
            </p>
          </div>
          <div class="button__content">
            <router-link to="/tata-cara" class="btn__orange">
              <span>
                CARA PENGGUNAAN
              </span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="right__body">
        <div class="list__pelayanan">
          <div class="header__pelayanan">
            <div class="mic__area">
              <img src="@/assets/images/mic.svg" alt="">
            </div>
            <h3>Pelayanan</h3>
          </div>
          <template v-if="loading">
            <div class="text-center w-100">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
          <template v-else v-for="sidePelayanan in pelayanans" :key="sidePelayanan.id">
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                <b>{{ sidePelayanan.nama }}</b>
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="kuota__pelayanan">
                Sisa Kuota : {{ `${sidePelayanan.kuota_tersedia}` }}
                <!-- {{ sidePelayanan.buka ? `${sidePelayanan.kuota} Kuota` : 'Pelayanan Tutup' }} -->
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                Jam Buka : {{ sidePelayanan.jam_buka }}
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                Jam Tutup : {{ sidePelayanan.jam_tutup }}
              </div>
            </div>
          </template>
          <div class="item__pelayanan" v-if="pelayanans.length === 0">
            <div class="title__pelayanan">
              Pelayanan sedang tutup
            </div>
          </div>
        </div>
        <div class="credits__body">
          <div class="header__pelayanan">
            <div class="site__area">
            <img src="@/assets/images/area.svg" alt="">
            </div>
            <h4>Informasi & Pertanyaan </h4>
          </div>
          <div class="detail__credits">
            <h5>Kantor Pertanahan <br> Kota Balikpapan</h5>
            <span>Jl. Manuntung No. 03, Balikpapan</span>
            <span>Telp. (0542) 8514647</span>
            <div class="copyright">
              <span>© {{ year }} Viral BPN</span>
              <span> Developed with love by <a href="https://dawnesiaofficial.com" target="_blank">Dawnesia</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { GetLine } from '@/bridge/connection.js';

  export default {
    name: 'App',
    data() {
      return {
        year: new Date().getFullYear(),
        pelayanans: [],
        loading: true,
      }
    },
    mounted() {
      this.pelayanan();
    },
    methods: {
      async pelayanan() {
        try {
          const result = await GetLine('api/list-pelayanan');
          this.pelayanans = result.data;
          this.loading = false;
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
</script>