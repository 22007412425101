<template>
  <div>
    Error Page
  </div>
</template>
<script>

  export default {
    name: 'ErrorNotFound',
  }
</script>