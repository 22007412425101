<template>
  <div class="left__body video__frame">
    <div class="waiting__petugas" v-if="detailData.status === 'ready'">
        <div class="container-petugas text-center w-100">
            <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
            </div>
            <div class="text-center w-100 mt-4">
                <h4 class="px-2">
                    Untuk memperlancar layanan, disarankan menggunakan <b>Headphone/Headset</b> bermicrophone. 
                    <br>
                    <br>
                    Berikan akses <b>Kamera & Microphone</b> anda pada aplikasi dengan menekan tombol <b>Allow/Izinkan</b> untuk memulia video call.
                    <br>
                    <br>
                    Menunggu Petugas,
                    Perkiraan waktu tunggu <b> {{ detailData.waktuPerkiraan / 60 }} menit </b>.
                    <br>
                    Jangan tutup halaman ini !
                </h4>
            </div>
        </div>
    </div>
    <div class="waiting__petugas" v-if="onUpload">
        <div class="container-petugas text-center w-100">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="text-center w-100">
                <h4>Progress Upload Video ...</h4>
            </div>
        </div>
    </div>
    <!-- socketURL="http://localhost:3000" -->
    <vue-webrtc 
        socketURL="https://socket.viralbpnbalikpapan.id"
        ref="webrtc"
        width="100%"
        :roomId="roomId"
        :enableLogs="true"
        v-on:joined-room="logEvent"
        v-on:left-room="logEvent"
        v-on:opened-room="logEvent"
        v-on:share-started="logEvent"
        v-on:share-stopped="logEvent"
        @error="onError" 
    />
  </div>
</template>

<script>
  import { ref } from 'vue';
  import { VueWebRTC } from 'vue-webrtc';
  import { useRouter } from 'vue-router';
  import Swal from 'sweetalert2';

  export default {
        name: 'demo-component',
        components: {
            'vue-webrtc': VueWebRTC
        },
        expose: ['onLeave', 'roomPerson'],
        setup(){
            // vue router
            const router = useRouter();
            const webrtc = ref(null);
            return {
                router,
                webrtc
            };
        },
        props: {
            dataroomid: {
                type: String,
                required: true
            },
            detailData: {
                type: Object,
                required: true
            },
            onUpload: {
                type: Boolean,
                required: true
            }
        },
        watch: {
            roomId: {
                handler: function (val, oldVal) {
                    console.log('watch roomId', val, oldVal);
                    this.onJoin();
                },
                deep: true
            },
            // watch roomPerson
            roomPerson: {
                handler: function (val) {
                    console.log('watch roomPerson', val);
                },
                deep: true
            },
            // watch this.webrtc
            webrtc: {
                handler: function (val) {
                    console.log('watch webrtc', val);
                },
                deep: true
            },
        },
        data() {
            return {
                img: null,
                roomId: null,
                loading: true,
                roomPerson: [],
                tokenData: null,
                kodeInRoom: null,
                logPromise: null,
                logResolve: null,
            };
        },
        mounted: function () {
            console.log(this.detailData);
            setInterval(() => {
                // remove controls on tag video
                var videos = document.getElementsByTagName("video");
                for (var i = 0; i < videos.length; i++) {
                    videos[i].removeAttribute("controls");
                }
            }, 1000);
            this.roomId = this.dataroomid;

            this.logPromise = new Promise((resolve) => {
                this.logResolve = resolve;
            });

            const originalConsoleLog = console.log;
            console.log = (...args) => {
                if (args[0] && typeof args[0] === 'string' && args[0].includes('Error connecting to peer')) {
                // Menyelesaikan promise dengan pesan log
                    // swal message to user `Jaringan anda tidak setabil, mohon lakukan refresh pada page / mengganti dengan jaringan yang lebih baik`
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Jaringan anda tidak setabil, mohon lakukan refresh pada page / mengganti dengan jaringan yang lebih baik. Abaikan Jika sudah Terkoneksi dengan baik!',
                        showConfirmButton: true,
                        confirmButtonText: 'Refresh',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        // text close button
                        showCancelButton: true,
                        cancelButtonText: 'Abaikan',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                    localStorage.setItem('problem_jaringan', 'true');
                    this.logResolve(args[0]);
                }
                originalConsoleLog(...args);
            };
        },
        computed: {
        },
        methods: {
            onJoin() {
                try {
                    this.webrtc.join();
                } catch (error) {
                    this.webrtc.join();
                    console.log(error, 'error join')
                }
            },
            onLeave() {
                this.webrtc.leave();
            },
            onError(error, stream) {
                console.log('On Error Event', error, stream);
            },
            logEvent(event) {
                // let kodeData = null;
                if (event !== this.roomId) {
                    if (this.kodeInRoom === null) {
                        this.kodeInRoom = event;
                    }
                    let roomIn = this.roomPerson;
                    // check event in roomPerson
                    if (roomIn.includes(event)) {
                        // check in roomPerson already have event in object on array roomPerson
                        const index = roomIn.findIndex((item) => item.event === event);
                        if (index !== -1) {
                            // remove event in roomPerson
                            roomIn.slice(index, 1);
                            this.roomPerson.splice(index, 1);
                        }
                    } else {
                        let objectEvent = {
                            event: event,
                            token: null
                        };
                        // add event in roomPerson
                        const params = this.router.currentRoute.value.query;
                        if (params.token){ 
                            if (event === this.kodeInRoom) {
                                this.tokenData = params.token;
                                objectEvent.token = params.token;
                            }
                        }
                        roomIn.push(objectEvent);
                    }
                    // // check id in video
                    new Promise((resolve) => {
                        // Dapatkan elemen vue-webrtc
                        var vueWebrtc = document.querySelector('.video-list');
                        
                        // Buat observer
                        var observer = new MutationObserver((mutations, observer) => {
                            mutations.forEach((mutation) => {
                            if (mutation.type == "childList") {
                                var videoItems = vueWebrtc.querySelectorAll('.video-item');
                                
                                // Jika ada .video-item, resolve promise dan hentikan observer
                                if (videoItems.length > 0) {
                                observer.disconnect();
                                resolve(videoItems);
                                }
                            }
                            });
                        });

                        // Mulai observing
                        observer.observe(vueWebrtc, { childList: true, subtree: true });
                    }).then((videoItems) => {
                        let availableRoom = [];
                        videoItems.forEach(element => {
                            const selectorVideo = element.querySelector('video');
                            availableRoom.push(selectorVideo.id);
                            // // console.log(selectorVideo.id, 'room yang dipanggil');
                            // const index = roomIn.findIndex((item) => item.event === selectorVideo.id);
                            // // remove video where video id not in roomIn
                            // console.log(index, 'roomIn');
                            // console.log(this.kodeInRoom, 'roomIn');
                            // if (roomIn[index]) {
                            //     console.log(roomIn[index], 'roomIn');
                            // }
                            // if (this.kodeInRoom === selectorVideo.id) {
                            //     // console.log(selectorVideo.id, 'room mu');
                            //     // // check if already has class title-video
                            //     // if (element.querySelector('.title-video')) {
                            //     //     // remove class title-video
                            //     //     element.querySelector('.title-video').remove();
                            //     // }
                            //     // // add div with class title-video and h1 in .video-item
                            //     // const divTitle = document.createElement('div');
                            //     // divTitle.classList.add('title-video');
                            //     // const h1 = document.createElement('h1');
                            //     // h1.textContent = this.detailData.register.nama;
                            //     // divTitle.appendChild(h1);
                            //     // element.appendChild(divTitle);
                            // }
                            // if(this.kodeInRoom !== selectorVideo.id) {
                            //     if (element.querySelector('.title-video')) {
                            //         // remove class title-video
                            //         element.querySelector('.title-video').remove();
                            //     }
                            //     // add div with class name of user and h1 in .video-item
                            //     const divTitle = document.createElement('div');
                            //     divTitle.classList.add('title-video');
                            //     const h1 = document.createElement('h1');
                            //     h1.textContent = this.detailData.user !== null ? this.detailData.user.name : 'Petugas';
                            //     divTitle.appendChild(h1);
                            //     element.appendChild(divTitle);
                            // }
                        });
                        this.roomPerson = availableRoom;
                    });
                }
                console.log('Event : ', event);
            },
        }
  };
</script>

<style>
  .btn {
     margin-right: 8px;
  }
</style>