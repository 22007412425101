import { createApp } from 'vue'
import vue3StarRatings from "vue3-star-ratings"
import { VueReCaptcha } from "vue-recaptcha-v3";
import App from './App.vue'
import router from "./router"

import "bootstrap/dist/css/bootstrap.min.css"

const app = createApp(App)
app.use(router)
app.use(VueReCaptcha, { siteKey: "6LcjHGYnAAAAAGKNmi--DiLRSkRmNKAAvq5a_Cgn" });
app.component("vue3-star-ratings", vue3StarRatings)
app.mount('#app')

import "bootstrap/dist/js/bootstrap.min.js"