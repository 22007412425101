
<template>
  <div class="frama__app">
    <div class="header__page">
      <router-link to="/" class="left__content">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </div>
        <div class="title__web">
          <h1>VIRAL BALIKPAPAN</h1>
          <span>Kantor Pertanahan Kota Balikpapan</span>
        </div>
      </router-link>
      <div class="right__content">
        <router-link to="/register" class="btn__regis">
          <img src="@/assets/images/link.svg" alt="">
          <span>
            Registrasi Layanan
          </span>
        </router-link>
        <router-link to="/room" class="btn__pink">
          <img src="@/assets/images/area.svg" alt="">
          <span>
            Masuk Room
          </span>
        </router-link>
      </div>
    </div>
    <div class="body__page">
      <div class="left__body">
        <div class="form__frame">
          <template v-if="loading">
            <div class="text-center w-100">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="form__register">
              <div class="header__pelayanan centerd--header__pelayanan"> 
                <h4>Terimakasih telah menggunakan layanan kami.</h4>
                <br>
                <h4>Berikan rating agar kami dapat terus memperbaiki pelayanan kami.</h4>
              </div>
              <div class="row g-3">
                <div class="col-md-12">
                  <table class="table table-bordered w-100">
                    <tbody>
                      <tr>
                        <td style="width: 20%;"><b>Nama Petugas :</b></td>
                        <td class="cap">{{ detailData.user.name }}</td>
                      </tr>
                      <tr>
                        <td style="width: 20%;"><b>Nama Operator :</b></td>
                        <td class="cap">{{ detailData.operator ? detailData.operator.name : '' }}</td>
                      </tr>
                      <tr>
                        <td style="width: 20%;"><b>Nama Pemohon :</b></td>
                        <td class="cap">{{ detailData.register.nama }}</td>
                      </tr>
                      <tr>
                        <td style="width: 20%;"><b>Jenis Pelayanan :</b></td>
                        <td class="cap">{{ detailData.register.layanan.nama }}</td>
                      </tr>
                      <tr>
                        <td colspan="2"><b>Keperluan :</b></td>
                      </tr>
                      <tr>
                        <td class="cap" colspan="2">{{ detailData.register.keperluan }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="w-100 d-flex align-items-center justify-content-center">
                    <vue3-star-ratings
                      v-model="form.rating"
                      starSize="40"
                      starColor="#ff9800"
                      inactiveColor="#333333"
                      controlBg="#2e5090"
                      controlColor="#ffffff"
                      :numberOfStars="5"
                      :step="0.5"
                      :showControl="false" 
                      :disableClick="disableRating" 
                    />
                  </div>
                </div>
                <form @submit.prevent="submitForm" v-if="!disableRating" class="col-12 d-flex align-items-center justify-content-end">
                  <button type="submit" class="btn btn-success btn-lg" style="text-transform: uppercase;">Submit Rating</button>
                </form>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="right__body">
        <div class="list__pelayanan">
          <div class="header__pelayanan">
            <div class="mic__area">
              <img src="@/assets/images/mic.svg" alt="">
            </div>
            <h3>Pelayanan</h3>
          </div>
          <template v-if="loading">
            <div class="text-center w-100">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
          <template v-else v-for="sidePelayanan in pelayanans" :key="sidePelayanan.id">
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                <b>{{ sidePelayanan.nama }}</b>
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="kuota__pelayanan">
                Sisa Kuota : {{ `${sidePelayanan.kuota_tersedia}` }}
                <!-- {{ sidePelayanan.buka ? `${sidePelayanan.kuota} Kuota` : 'Pelayanan Tutup' }} -->
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                Jam Buka : {{ sidePelayanan.jam_buka }}
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                Jam Tutup : {{ sidePelayanan.jam_tutup }}
              </div>
            </div>
          </template>
          <div class="item__pelayanan" v-if="pelayanans.length === 0">
            <div class="title__pelayanan">
              Pelayanan sedang tutup
            </div>
          </div>
        </div>
        <div class="credits__body">
          <div class="header__pelayanan">
            <div class="site__area">
            <img src="@/assets/images/area.svg" alt="">
            </div>
            <h4>Informasi & Pertanyaan </h4>
          </div>
          <div class="detail__credits">
            <h5>Kantor Pertanahan <br> Kota Balikpapan</h5>
            <span>Jl. Manuntung No. 03, Balikpapan</span>
            <span>Telp. (0542) 8514647</span>
            <div class="copyright">
              <span>© {{ year }} Viral BPN</span>
              <span> Developed with love by <a href="https://dawnesiaofficial.com" target="_blank">Dawnesia</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { createToaster } from "@meforma/vue-toaster";
import { GetLine, PostLine } from '@/bridge/connection.js';
import { useRouter } from 'vue-router';

const router = useRouter();

const loading = ref(true);

const toaster = createToaster({
  position: "top-right",
});

const disableRating = ref(false);

const year = ref(new Date().getFullYear());

const roomId = ref('');

const detailData = ref(null);

const pelayanans = ref([]);

const form = ref({
  rating: 0,
  room: '',
});


// watch roomId
watch(roomId, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    form.value.room = newValue;
    joinRoom();
  }
});

// watch detailData
watch(detailData, () => {
  loading.value = false;
});

onMounted(() => {
  getPelayanan();
  if (router.currentRoute.value.params.id) {
    roomId.value = router.currentRoute.value.params.id;
  }
});

const getPelayanan = async () => {
  try {
    const result = await GetLine('api/list-pelayanan');
    pelayanans.value = result.data;
  } catch (error) {
    console.log(error);
  }
}

const submitForm = async () => {
  try {
    // make rating rounded
    console.log(form.value.rating)
    loading.value = true;
    await PostLine('api/rating-room', form.value);
    toaster.success("Aksi Berhasil: Terima Kasih Telah Mengisi Rating!");
    router.push('/');
  } catch (error) {
    loading.value = false;
    if (error.error?.data?.data == 'DATA_NOT_FOUND') {
      toaster.error("Aksi Gagal: Layanan tidak ditemukan!");
    }
    else if (error.error?.data?.data == 'RATING_ALREADY') {
      toaster.error("Aksi Gagal: Sudah Diberi rating!");
    } else {
      toaster.error("Aksi Gagal: Mohon isi Semua Form!");
    }
  }
}

const joinRoom = async () => {
  try {
    loading.value = true;
    const data = await GetLine(`api/find-rooms/${roomId.value}`);
    detailData.value = data.data;
    if (data.data.ratings !== null) {
      disableRating.value = true;
      form.value.rating = data.data.ratings;
      toaster.error("Rating Sudah Diisi!");
    }
  } catch (error) {
    console.log(error)
    if (error.data?.data == 'DATA_NOT_FOUND') {
      toaster.error("Aksi Gagal: Room tidak ditemukan!");
    }
    if (error.data?.data == 'ROOM_CLOSED') {
      toaster.error("Aksi Gagal: Room sudah ditutup!");
    }
  }
}

</script>