<template>
  <div class="frama__app">
    <div class="header__page flex--mobile">
      <router-link to="/" class="left__content">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </div>
        <div class="title__web">
          <h1>Room {{ roomId }}</h1>
          <span>{{ pelayanan === '' ? 'Tidak Ada Pelayanan Terkait' : pelayanan }}</span>
        </div>
      </router-link>
      <div class="right__content">
        <div class="text__waktu">
          Waktu Layanan  
          {{ textWaktu }}
        </div>
      </div>
    </div>
    <div class="button__action--button" v-if="roomMaster">
      <button v-if="!record && !upload" class="btn btn-success" @click="recordRoom" type="button">
        Rekam Room
      </button>
      <button class="btn btn-danger" type="button" @click="leaveRoomAdmin" v-if="tokenData && detail.roles && detail.roles === 'admin'">Keluar Room</button>
      <button v-if="record && !upload" class="btn btn-danger" @click="stopRecord" type="button">
        Simpan Rekaman
      </button>
      <button v-if="record && upload" class="btn btn-danger" @click="leaveRoom" type="button">
        Bubarkan Room
      </button>
      <button v-if="detail && detail.record !== null" class="btn btn-danger" @click="leaveRoom" type="button">
        keluar Video Room
      </button>
    </div>
    <div class="body__page p-0">
      <template v-if="detail == null">
        <div class="w-100 loading__container">
          <div class="text-center w-100">
            <template v-if="loading">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <h5 class="mt-3">Memuat room...</h5>
            </template>
            <div class="text-center w-100" v-else>
              <h4>Room Tidak Tersedia</h4>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <demo-component ref="demoComponent" :dataroomid="roomId" :detailData="detail" :onUpload="uploadProgress"></demo-component>
        <div class="right__body padding-on-mobile">
          <div class="list__pelayanan">
            <div class="header__pelayanan">
              <div class="mic__area">
                <img src="@/assets/images/mic.svg" alt="">
              </div>
              <h3>
                Detail Room
              </h3>
            </div>
            <template v-if="detail == null">
              <div class="item__pelayanan">
                <div class="text-center w-100">
                  <div class="spinner-border text-primary" role="status" v-if="loading">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="text-center w-100" v-else>
                    <h4>Room Tidak Tersedia</h4>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="item__pelayanan border__petugas">
                <div class="title__pelayanan pelayanan__detail">
                  <b>Petugas :</b> {{ detail.user_id !== null ? detail.user.name : '-' }}
                </div>
              </div>
              <div class="item__pelayanan border__petugas" v-if="detail.operator_id !== null">
                <div class="title__pelayanan pelayanan__detail">
                  <b>Operator :</b> {{ detail.operator_rooms }}
                </div>
              </div>
              <div class="item__pelayanan border__petugas" v-if="detail.operator_id === null && tokenData !== ''">
                <form class="title__pelayanan pelayanan__detail--user w-100" @submit="submitOperator" onsubmit="return false;" v-if="!loadingOperator">
                  <b class="mt-2">Pindah Ke Operator</b>
                  <select v-model="operatorId" name="operator_list" id="operator_list" class="form-control mt-2">
                    <option value="" disabled selected>Pilih Operator</option>
                    <option v-for="op in operator_list" :value="op.id" :key="op.id">
                      {{ op.name }} ({{ op.jabatan }})
                    </option>
                  </select>
                  <br>
                  <button class="btn btn-sm btn-primary float-end text-white" type="submit">
                    Submit Operator
                  </button>
                </form>
                <div class="text-center w-100" v-else>
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <div class="item__pelayanan" v-if="detail.register.file_url">
                <div class="title__pelayanan pelayanan__detail--user">
                  <b>Berkas Pendukung :</b> 
                  <a :href="detail.register.file_url" target="_blank">
                    Lihat File
                  </a>
                </div>
              </div>
              <div class="item__pelayanan">
                <div class="title__pelayanan pelayanan__detail--user">
                  <b>Nama Pemohon :</b> {{ detail.register.nama }}
                </div>
              </div>
              <div class="item__pelayanan">
                <div class="title__pelayanan pelayanan__detail--user">
                  <b>NIK :</b> {{ detail.register.nik }}
                </div>
              </div>
              <div class="item__pelayanan">
                <div class="title__pelayanan pelayanan__detail--user">
                  <b>Alamat :</b> {{ detail.register.alamat }}
                </div>
              </div>
              <div class="item__pelayanan">
                <div class="title__pelayanan pelayanan__detail--user">
                  <b>Kode Booking :</b> {{ detail.register.kode_booking_view }}
                </div>
              </div>
              <div class="item__pelayanan">
                <div class="title__pelayanan pelayanan__detail--user">
                  <b>No. HP :</b> {{ detail.register.no_hp }}
                </div>
              </div>
              <div class="item__pelayanan">
                <div class="title__pelayanan pelayanan__detail--user">
                  <b>Email :</b> {{ detail.register.email }}
                </div>
              </div>
              <div class="item__pelayanan">
                <div class="title__pelayanan pelayanan__detail--user">
                  <b>Keperluan :</b>
                </div>
              </div>
              <div class="item__pelayanan">
                <div class="title__pelayanan">
                   {{ detail.register.keperluan }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Demo from '@/components/Demo.vue'
  import { ref } from 'vue';
  import { GetLine, PostLine } from '@/bridge/connection.js';
  import { createToaster } from "@meforma/vue-toaster";
  import { useRouter } from 'vue-router';
  import Echo from "laravel-echo";
  import Swal from 'sweetalert2';
  import Pusher from "pusher-js";
  window.Pusher = Pusher;
  const toaster = createToaster({
    position: "top-right",
  });
  export default {
    name: 'App',
    data() {
      return {
        operatorId: '',
        loadingOperator: false,
        waktuTunggu: 10 * 60,
        textWaktu: '00 : 00',
        waktuStart: false,
        uploadProgress: false,
        recordedBlobs: null,
        mediaRecorder: null,
        operator: '',
        record: false,
        upload: false,
        year: new Date().getFullYear(),
        loading: true,
        detail: null,
        pelayanan: '',
        roomId: '',
        operator_list: [],
        roomMaster: false,
        tokenData: '',
        displayOptions: {
          video: {
            cursor: "always",
          },
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100,
          },
        },
      }
    },
    setup() {
      const demoComponent = ref(null);
      const router = useRouter();
      
      return {
        demoComponent,
        router,
      }
    },
    watch: {
      roomId: {
        handler: function (val) {
          if (val) {
            this.joinRoom();
          }
        },
        deep: true
      },
      mediaRecorder: {
        handler: function (val) {
          if (val) {
            if (val.state !== "inactive") {
              this.record = true;
            }
          }
        },
        deep: true
      },
      detail: {
        handler: function (val) {
          if (val) {
            // this.countDown();
            if (val.status_masuk === 'petugas') {
              this.roomMaster = true;
            }
            if(val.status === 'dilayani') {
              this.waktuStart = true;
            }
            if (val.status === 'close') {
              setTimeout(() => {
                this.demoComponent.onLeave();
              }, 1500);
              if (val.ratings === null) {
                toaster.success("Silahkan Beri Rating Petugas Kami!");
                this.$router.push({ name: 'ratings' });
              } else {
                toaster.error("Sesi Terlah Berakhir!");
                this.$router.push({ name: 'home' });
              }
            }
          }
        },
        deep: true
      },
      waktuStart: {
        handler: function (newVal, oldVal) {
          if (newVal !== oldVal) {
            if (newVal) {
              // this.countDown();
            }
          }
        },
        deep: true
      },
      // watch this.demoComponent.roomPerson
      'demoComponent.roomPerson': {
        handler: function (val) {
          if (val) {
            console.log(val, 'ini misal orang keluar jg kebaca');
            // this.joinRoom();
          }
        },
        deep: true
      },
      // tokenData
      // tokenData: {
      //   handler: function (val) {
      //     if (val) {
            
      //     }
      //   },
      //   deep: true
      // }
    },
    components: {
      'demo-component': Demo
    },
    mounted() {
      this.roomId = this.$route.params.id;
      console.log(process.env.VUE_APP_PUSHER_APP_KEY);
       const echo = new Echo({
          broadcaster: "pusher",
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          wsHost:
            process.env.VUE_APP_PUSHER_HOST ??
            `ws-${process.env.VUE_APP_PUSHER_APP_CLUSTER}.pusher.com`,
          wsPort: process.env.VUE_APP_PUSHER_PORT ?? 80,
          wssPort: process.env.VUE_APP_PUSHER_PORT ?? 443,
          forceTLS: (process.env.VUE_APP_PUSHER_SCHEME ?? "https") === "https",
          enabledTransports: ["ws", "wss"],
        });
        echo.channel(`Room.${this.$route.params.id}`)
            .listen('RoomEvent', (e) => {
              console.log(e);
              this.loadingOperator = false;
              this.joinRoom();
            });
        echo.channel(`Room.${this.$route.params.id}-event`)
            .listen('RoomEvent', () => {
              this.joinRoom();
              this.countDown();
            });
      // Pusher.logToConsole = true;
      // const pusher = new Pusher('9f8c2b00c888046552df', {
      //     cluster: 'ap1'
      // });
      // get :id from url add to roomId
      // const self = this;
      // var channel = pusher.subscribe(`Room.${this.$route.params.id}`);
      //   channel.bind('RoomEvent', function(data) {
      //       try {
      //           console.log(data, 'ini dari event channel');
      //           self.loadingOperator = false;
      //           self.joinRoom();
      //       } catch (error) {
      //           console.error('Error during event handling:', error);
      //       }
      //   });
    },
    methods: {
      async submitOperator() {
        console.log(this.operatorId);
        this.loadingOperator = true;
        let idData = this.operatorId;
        if (idData !== '') {
          await PostLine(`api/select-operator`, {
            operator: idData,
            room: this.roomId,
            token: this.tokenData
          });
        } else {
          toaster.error("Silahkan Pilih Operator!");
        }
      },
      countDown() {

        // make countdown this.waktuTunggu minutes and update text in every 1 second this.textWaktu
        let waktu = this.detail.waktuPerkiraan || this.waktuTunggu;
        let menit = 0;
        let detik = 0;
        let textWaktu = '';
        const countDown = () => {
          if (waktu > 0) {
            waktu--;
            menit = Math.floor(waktu / 60);
            detik = waktu % 60;

            // Create strings with padding for display
            let menitString = menit < 10 ? `0${menit}` : `${menit}`;
            let detikString = detik < 10 ? `0${detik}` : `${detik}`;

            textWaktu = `${menitString} : ${detikString}`;
            this.textWaktu = textWaktu;
            // insert text to id waktu_countdown
            // document.getElementById('waktu_countdown').innerHTML = textWaktu;

            setTimeout(countDown, 1000); // Schedule the next countdown
          }
        };

        countDown()
      },
      async recordRoom() {
        try {
          const gdmOptions = this.displayOptions;
          let stream = await navigator.mediaDevices.getDisplayMedia(gdmOptions);
          //needed for better browser support
          const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9")
            ? "video/webm; codecs=vp9"
            : "video/webm";
          let mediaRecorder = new MediaRecorder(stream, {
            mimeType: mime,
          });

          let chunks = [];
          mediaRecorder.addEventListener("dataavailable", function (e) {
            chunks.push(e.data);
          });
          
          // PostLine to /api/send-event
          await PostLine(`api/send-event`, {
            room: this.roomId,
            token: this.tokenData
          });

          
          const self = this;
          mediaRecorder.addEventListener("stop", function () {
            let blob = new Blob(chunks, {
              type: chunks[0].type,
            });
            self.recordedBlobs = blob;
            self.stopRecording();
            self.stopRecord();
            console.log('closed page');
            // let url = URL.createObjectURL(blob);

            // let video = document.querySelector("video");
            // video.src = url;

            // let a = document.createElement("a");
            // a.href = url;
            // a.download = "video.webm";
            // a.click();
          });
          //we have to start the recorder manually
          mediaRecorder.start(10);
          this.mediaRecorder = mediaRecorder;
        } catch (error) {
          console.log(error);
        }
      },
      stopRecord() {
        if (this.mediaRecorder.state !== "inactive") {
          this.uploadProgress = true;
          this.mediaRecorder.stop();
        }
      },
      async stopRecording() {
        // set localStorage loading_upload
        localStorage.setItem('loading_upload', "true");
        this.upload = true;
        let formData = new FormData();
        formData.append('video', this.recordedBlobs);
        formData.append('room', this.roomId);
        formData.append('token', this.tokenData);
        
        // swal alert with message "Anda dapat membubarkan Room saat progress upload namun jangan keluar halaman page sampai message menghilang!"
        Swal.fire({
          title: 'Anda dapat membubarkan Room saat progress upload namun jangan keluar halaman page sampai message menghilang!',
          icon: 'info',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            Swal.hideLoading()
          }
        });
        await fetch(process.env.VUE_APP_API_URL+'api/upload-video', {
          method: 'POST',
          body: formData
        });
        this.uploadProgress = false;
        toaster.success("Rekaman berhasil disimpan!");
        // set localStorage loading_upload to false
        localStorage.setItem('loading_upload', "false");
      },
      async onLeaveRoom(admin_keluar = false) {
        // leaving-room
        let formData = new FormData();
        formData.append('room', this.roomId);
        formData.append('token', this.tokenData);
        if (admin_keluar) {
          formData.append('admin_keluar', 'true');
        }
        const data = await fetch(process.env.VUE_APP_API_URL+'api/leaving-room', {
          method: 'POST',
          body: formData
        });
        if (data) {
          this.demoComponent.onLeave();
          toaster.success("Berhasil Keluar Dari Room!");
          window.location = '/';
          this.$router.push({ name: 'home' });
        }
      },
      async leaveRoom() {
        this.onLeaveRoom();
        this.demoComponent.onLeave();
        toaster.success("Berhasil Keluar Dari Room!");
        this.$router.push({ name: 'home' });
      },
      async leaveRoomAdmin() {
        this.onLeaveRoom(true);
      },
      async joinRoom() {
        try {
          // get token query from url
          let token = '';
          if (this.detail === null) {
            token = '?status=ready';
          }
          const params = this.router.currentRoute.value.query;
          if (params.token) {
            token = `?token=${params.token}`;
            this.tokenData = params.token;
          }
          const data = await GetLine(`api/find-rooms/${this.roomId}${token}`);
          this.waktuTunggu = parseFloat(data.data.register.layanan.estimasi_layanan) * parseFloat(data.data.register.kode_booking) * 60;
          this.pelayanan = data.data.register.layanan.nama;
          let detailData = data.data;
          detailData.waktuPerkiraan = this.waktuTunggu;
          this.detail = detailData;
          if (data.data.operator_list) {
            this.operator_list = data.data.operator_list;
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error)
          if (error.data?.data == 'USER_ERROR') {
            toaster.error("Aksi Gagal: User Tidak Ditemukan!");
          }
          if (error.data?.data == 'DATA_NOT_FOUND') {
            toaster.error("Aksi Gagal: Room tidak ditemukan!");
          }
          if (error.data?.data == 'ROOM_CLOSED') {
            toaster.error("Aksi Gagal: Room sudah ditutup!");
          }
        }
      }
    },
  }
</script>