import {
  createRouter,
  createWebHistory,
} from "vue-router";


import HomePage from "./../views/HomePage.vue";
import RegisterPage from "./../views/RegisterPage.vue";
import RoomFormPage from "./../views/RoomFormPage.vue";
import RoomPage from "./../views/RoomPage.vue";
import RatingPage from "./../views/RatingPage.vue";
import TataCaraPage from "./../views/TataCaraPage.vue";
import PathNotFound from "./../views/ErrorNotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePage,
    },
    {
      path: "/register",
      name: "register",
      component: RegisterPage,
    },
    {
      path: "/tata-cara",
      name: "tatacara",
      component: TataCaraPage,
    },
    {
      path: "/room",
      name: "room",
      component: RoomFormPage,
    },
    {
      path: "/rooms/:id",
      name: "rooms",
      component: RoomPage,
    },
    {
      path: "/ratings/:id",
      name: "ratings",
      component: RatingPage,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: PathNotFound,
    },
  ],
});

export default router;
