
<template>
  <div class="frama__app">
    <div class="header__page">
      <router-link to="/" class="left__content">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="logo" />
        </div>
        <div class="title__web">
          <h1>VIRAL BALIKPAPAN</h1>
          <span>Kantor Pertanahan Kota Balikpapan</span>
        </div>
      </router-link>
      <div class="right__content">
        <router-link to="/register" class="btn__regis">
          <img src="@/assets/images/link.svg" alt="">
          <span>
            Registrasi Layanan
          </span>
        </router-link>
        <router-link to="/room" class="btn__pink">
          <img src="@/assets/images/area.svg" alt="">
          <span>
            Masuk Room
          </span>
        </router-link>
      </div>
    </div>
    <div class="body__page">
      <div class="left__body">
        <div class="form__frame">
          <template v-if="loading">
            <div class="text-center w-100">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="room_joined">
              <div class="header__pelayanan">
                <div class="mic__area">
                  <img src="@/assets/images/mic.svg" alt="">
                </div>
                <h3>Masuk ke Room</h3>
              </div>
              <form @submit.prevent="recaptcha" class="row g-3">
                <div class="col-md-12">
                  <label for="roomid" required class="form-label">Masukan Token Disini</label>
                  <input type="text" class="form-control" id="roomid" v-model="roomId">
                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </template>
        </div>
      </div>
      <div class="right__body">
        <div class="list__pelayanan">
          <div class="header__pelayanan">
            <div class="mic__area">
              <img src="@/assets/images/mic.svg" alt="">
            </div>
            <h3>Pelayanan</h3>
          </div>
          <template v-if="loading">
            <div class="text-center w-100">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
          <template v-else v-for="sidePelayanan in pelayanans" :key="sidePelayanan.id">
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                <b>{{ sidePelayanan.nama }}</b>
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="kuota__pelayanan">
                Sisa Kuota : {{ `${sidePelayanan.kuota_tersedia}` }}
                <!-- {{ sidePelayanan.buka ? `${sidePelayanan.kuota} Kuota` : 'Pelayanan Tutup' }} -->
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                Jam Buka : {{ sidePelayanan.jam_buka }}
              </div>
            </div>
            <div class="item__pelayanan">
              <div class="title__pelayanan">
                Jam Tutup : {{ sidePelayanan.jam_tutup }}
              </div>
            </div>
          </template>
          <div class="item__pelayanan" v-if="pelayanans.length === 0">
            <div class="title__pelayanan">
              Pelayanan sedang tutup
            </div>
          </div>
        </div>
        <div class="credits__body">
          <div class="header__pelayanan">
            <div class="site__area">
            <img src="@/assets/images/area.svg" alt="">
            </div>
            <h4>Informasi & Pertanyaan </h4>
          </div>
          <div class="detail__credits">
            <h5>Kantor Pertanahan <br> Kota Balikpapan</h5>
            <span>Jl. Manuntung No. 03, Balikpapan</span>
            <span>Telp. (0542) 8514647</span>
            <div class="copyright">
              <span>© {{ year }} Viral BPN</span>
              <span> Developed with love by <a href="https://dawnesiaofficial.com" target="_blank">Dawnesia</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { createToaster } from "@meforma/vue-toaster";
import { GetLine } from '@/bridge/connection.js';
import { useRouter } from 'vue-router';
import { useReCaptcha } from 'vue-recaptcha-v3';

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const router = useRouter();

const loading = ref(true);

const toaster = createToaster({
  position: "top-right",
});

const year = ref(new Date().getFullYear());

const roomId = ref('');

const pelayanans = ref([]);

onMounted(() => {
  getPelayanan();
});

const getPelayanan = async () => {
  try {
    const result = await GetLine('api/list-pelayanan');
    pelayanans.value = result.data;
    loading.value = false;
  } catch (error) {
    console.log(error);
  }
}

const recaptcha = async () => {
  loading.value = true;
  // (optional) Wait until recaptcha has been loaded.
  await recaptchaLoaded()

  // Execute reCAPTCHA with action "login".
  const token = await executeRecaptcha('login')

  // Do stuff with the received token.
  if (token) {
    joinRoom();
  }
}

const joinRoom = async () => {
  try {
    loading.value = true;
    await GetLine(`api/find-rooms/${roomId.value}`);
    router.push({ name: 'rooms', params: { id: roomId.value } });
  } catch (error) {
    loading.value = false;
    console.log(error)
    if (error.data?.data == 'DATA_NOT_FOUND') {
      toaster.error("Aksi Gagal: Room tidak ditemukan!");
    }
    if (error.data?.data == 'ROOM_CLOSED') {
      toaster.error("Aksi Gagal: Room sudah ditutup!");
    }
  }
}

</script>